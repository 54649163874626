
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import mixin from './plugins/mixin'
import * as moment from 'moment-timezone';
moment.tz.setDefault('Europe/London');
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
import "leaflet.icon.glyph"
import L from 'leaflet'
delete L.Icon.Default.prototype._getIconUrl;

Vue.prototype.moment = moment

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

new Vue({
  router,
  store,
  vuetify,
  mixin,
  moment,
  render: h => h(App)
}).$mount('#app')
