/* eslint-disable */
import * as moment from 'moment-timezone';
moment.tz.setDefault('Europe/London');

export default {
  methods: {
    msToHHMM(ms) {
      var seconds = ms / 1000;
      var hours = parseInt( seconds / 3600 );
      seconds = seconds % 3600;
      var minutes = parseInt( seconds / 60 );

      return ("0" + hours).slice(-2)+":"+("0" + minutes).slice(-2);
    },
    dateToHHMM(date) {
      if (!date) return null;
      return moment.unix(new Date(date).getTime()/1000).format('HH:mm');
    },
    dateToYYYYMMDD(date) {
      if (!date) return null;
      return moment.unix(new Date(date).getTime()/1000).format('YYYY-MM-DD');
    },
    dateToYYYYMMDDHHMM(date) {
      if (!date) return null;
      return moment.unix(new Date(date).getTime()/1000).format('YYYY-MM-DD HH:mm');
    },
    dateToDDMM(date) {
      if (!date) return null;
      return moment.unix(new Date(date).getTime() / 1000).format('DD/MM');
    },
    subtractDays(days){
      //TODO adapt to receive date as parameter
      return moment().subtract(days, "days");
    },
    formatDate(date) {
      if (!date) return null;
      return moment.unix(date/1000).format('MM/DD/YYYY');
    },
    imagemFoto(imagem) {
      return this.API_URL+`${imagem}`;
    },
    getColorNivelSensor(nivel) {
      if (nivel > 75) return "red";
      else if (nivel > 50) return "orange";
      else if (nivel > 25) return "yellow";
      else if (nivel) return "green";
      else return "white";
    },
    getColorNivelSensorTabela(nivel) {
      if (nivel > 75) return "#f82f2f";
      else if (nivel > 50) return "#fb8c00";
      else if (nivel > 25) return "#f0df16";
      else return "#4caf50";
    },
    getFreguesias() {
      return this.$http
      .get("graphql?query={ \
        freguesias{ \
            id, \
            designacao \
        } \
      }",
      {
        headers: {
          Authorization: `Bearer ${this.getObjeto("token")}`,
        },
      })
      .catch((error) => {
        console.log(error);
      })
    },
    getDesignacaoFreguesia(freguesias, id){
      let freg = freguesias.find( freg => freg.id == id);
      return freg ? freg.designacao : "ND"
    },
  }
}
